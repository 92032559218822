import { Controller } from "@hotwired/stimulus"

export default class NavigationMenuController extends Controller {
  static targets = ["nav"]

  show() {
    this.navTarget.classList.add("show")
  }

  hide() {
    this.navTarget.classList.remove("show")
  }

  hideNavWhenResize() {
    const windowWidth = window.innerWidth
    const tabletBreakpoint = 768

    if (windowWidth > tabletBreakpoint) {
      this.navTarget.classList.remove("show")
    }
  }
}
import "@hotwired/turbo-rails"
import { application } from "../controllers/application.js"

import "quill/dist/quill.snow.css"

import NavigationMenuController from "../controllers/navigation_menu_controller"
application.register("navigation-menu", NavigationMenuController)

import AvailablePackagesController from "../controllers/available_packages_controller"
application.register("available-packages", AvailablePackagesController)

import "../src/landing/past_works.js"
import "../src/landing/services.js"

document.addEventListener("turbo:load", function() {
  const services = document.getElementById("services")
  if (services != null) {
    const buttons = services.querySelectorAll(".service-card button")

    buttons.forEach(button => {
      button.addEventListener("click", function(e) {
        // Toggle card content
        const paragraph = e.target.previousElementSibling
        paragraph.classList.toggle("expand")
      })
    })
  }
})
document.addEventListener("turbo:load", function() {
  const pastWorks = document.getElementById("past-works")
  if(pastWorks != null) {
    let allTabMenus = document.querySelectorAll(".tab-menu div[role='button']")
    let allTabBodies = document.querySelectorAll(".tab-body")

    allTabMenus.forEach(t => {
      t.addEventListener("click", function(e) {
        // toggle tab menu item
        let selectedItem = e.currentTarget
        allTabMenus.forEach(t => t.classList.remove("selected"))
        selectedItem.classList.add("selected")

        // toggle content at tab body
        let index = e.currentTarget.dataset.tabIndex
        let tabBody = document.getElementById(`tab-body-${index}`)
        allTabBodies.forEach(b => b.classList.remove("selected"))
        tabBody.classList.add("selected")
      })
    })
  }
})
